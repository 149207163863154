<template>
  <b-sidebar
    id="add-new-process-category-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :no-close-on-backdrop="
      $refs.refFormObserver && $refs.refFormObserver.flags.dirty
    "
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ title }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Prefix -->
          <validation-provider
            #default="validationContext"
            name="Prefix"
            rules="required"
          >
            <b-form-group label="Prefix" label-for="prefix">
              <b-form-input
                id="prefix"
                v-model="form.prefix"
                maxlength="255"
                autofocus
                :state="getValidationState(validationContext)"
                :plaintext="isReadonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Prefix -->
          <validation-provider #default="validationContext" name="Description">
            <b-form-group label="Description" label-for="description">
              <b-form-input
                id="description"
                v-model="form.description"
                maxlength="255"
                autofocus
                :state="getValidationState(validationContext)"
                :plaintext="isReadonly"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              v-if="!isReadonly"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              Save
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, alphaNum, email } from '@validations'
  import Ripple from 'vue-ripple-directive'
  // import vSelect from 'vue-select'
  import { mapState } from 'vuex'
  import countries from '@/@fake-db/data/other/countries'

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      // vSelect,

      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isSidebarActive',
      event: 'update:is-sidebar-active',
    },
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true,
      },
      isReadonly: {
        type: Boolean,
      },
      value: {
        type: Object,
        default: () => this.getDefaultForm(),
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
        form: this.getDefaultForm(),
      }
    },
    computed: {
      ...mapState('app', ['isLoading']),
      isEditing() {
        return !!this.value
      },
      title() {
        if (this.isReadonly) {
          return 'Process Category Details'
        }
        return this.isEditing
          ? 'Edit Process Category'
          : 'Add New Process Category'
      },
    },
    watch: {
      value(value) {
        if (value) {
          this.form = {
            ...value,
            __typename: undefined,
          }
        }
      },
    },
    methods: {
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      resetForm() {
        this.$emit('update:value', this.getDefaultForm())
        this.$nextTick(() => {
          this.$refs.refFormObserver.reset()
        })
        this.form = this.getDefaultForm()
      },
      onSubmit() {
        const action = this.isEditing
          ? this.$store.dispatch(
              'processCategory/updateProcessCategory',
              this.form
            )
          : this.$store.dispatch(
              'processCategory/addProcessCategory',
              this.form
            )

        action.then(() => {
          this.$emit('update:is-sidebar-active', false)
        })
      },
      getDefaultForm() {
        return {
          prefix: '',
          description: '',
        }
      },
    },
  }
</script>

<style lang="scss">
  @import '../../@core/scss/vue/libs/vue-select';

  #add-new-process-category-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
